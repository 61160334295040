export default class TrustindexFeedLightboxModule {
	constructor(widgetInstance) {
		this.content = null;
		this.actPost = null;
		this.siblings = {
			prev: null,
			next: null,
		};

		this.widgetInstance = widgetInstance;
		this.handleNavPostEvent = this.navPostEvent.bind(this);
	}

	open(postId, mediaIndex) {
		// open lightbox
		console.log('[Trustindex Feed] post open in lightbox ', postId, ' on slider state ', mediaIndex);

		let lightboxType = this.widgetInstance.data.style.lightbox.type;

		if (this.content) {
			this.close();
		}

		let posts = this.widgetInstance.getPosts(),
			post = null;

		if ('scroller' !== lightboxType) {
			// display only one post in lightbox
			posts.forEach((p, i) => {
				let isReposted = false;
				if (p.repost_content && p.repost_content.length) {
					p.repost_content.forEach((rp) => {
						if (rp.id == postId) {
							isReposted = true;
						}
					})
				}

				if ((p.id == postId && 'text' !== p.type) || isReposted) {
					post = p;
					this.actPost = p.id;
					this.siblings.prev = 'undefined' !== typeof posts[i - 1] ? posts[i - 1].id : null;
					this.siblings.next = 'undefined' !== typeof posts[i + 1] ? posts[i + 1].id : null;

					// set media lightbox on loop
					if ('media' === lightboxType) {
						this.siblings.prev = this.siblings.prev || posts[ posts.length - 1 ].id;
						this.siblings.next = this.siblings.next || posts[ 0 ].id;
					}
				}
			});

			posts = [post];
		}

		TrustindexWidgetBuilder.buildContentHTML(
			TrustindexFeed.getTargetDocument().body,
			[{ // .ti-lightbox
					node: '.ti-lightbox',
					attr: {
						'id': 'ti-lightbox',
						'data-lightbox-type': lightboxType,
						'data-wkey': this.widgetInstance.getWKey(),
					},
					content: [{
							node: '.ti-lightbox-close'
						},
						{
							node: '.ti-lightbox-inner',
							content: posts.map((post) => this.widgetInstance.buildCard(post, 'lightbox')),
						},
						lightboxType === 'media'
							? this.widgetInstance.buildNavArrows(this.widgetInstance.data.style.arrow.type)
							: {}
					]
				} // .ti-lightbox
			]
		);

		this.content = TrustindexFeed.getTargetDocument().querySelector('#ti-lightbox');

		if ('scroller' === lightboxType) {
			// load all the images and scroll to the selected card
			this.widgetInstance.loadImg(this.content);

			let innerContainer = this.content.querySelector('.ti-lightbox-inner'),
				actCard = innerContainer.querySelector('.ti-widget-card[data-post-id="' + postId + '"]');

			// scroll to tha selected card
			innerContainer.scrollTo(0, actCard.offsetTop - 100);

			let widgetInstance = this.widgetInstance;
			let playOnViewport = () => {
				for (let card of innerContainer.children) {
					let media = card.querySelector('.ti-card-media'),
						rect = media ? media.getBoundingClientRect() : null;
					if (rect && rect.top >= 0 && rect.bottom <= (window.innerHeight || document.clientHeight)) {
						widgetInstance.videoToggle(
							card.querySelector('video')
						);
					}
				}
			}

			playOnViewport();
			innerContainer.addEventListener('scroll', playOnViewport);
		} else {
			// set arrow visibility (by its has prev or next post or if it is a carousel album, has next slide)
			let selectorPrefix = ('media' === lightboxType ? ':scope > ' : '');
			for (const [direction, siblingPostId] of Object.entries(this.siblings)) {
				let arrow = this.content.querySelector(selectorPrefix + '.ti-nav-arrow .ti-arrow-' + direction),
					slider = this.content.querySelector('.ti-card-media [data-behaviour="slide"]');
				if (siblingPostId || (slider && this.widgetInstance.sliderHasNext(slider, 'next' === direction))) {
					arrow.style.removeProperty('visibility')
				} else {
					arrow.style.visibility = 'hidden';
				}
			}

			// display images
			this.widgetInstance.loadImg(this.content.querySelector('.ti-card-media'));

			// play video on open
			this.widgetInstance.videoToggle(
				this.content.querySelector('.ti-slider-item:first-child > video, .ti-card-media > video')
			);
		}

		// if the lightbox card type is carousel album than slide to the given media index on open
		let slider = this.content.querySelector('.ti-widget-card[data-post-id="' + postId + '"] .ti-card-slider[data-behaviour="slide"]');
		if (slider && mediaIndex > 0 && mediaIndex < slider.childElementCount) {
			let actState = slider.state ? Math.abs(slider.state) : 0;
			while (actState !== mediaIndex) {
				this.widgetInstance.slideFeed(slider, actState < mediaIndex, false, 0);
				actState = Math.abs(slider.state);
			}
		}

		// add event(s) to get next post
		TrustindexFeed.getTargetWindow().addEventListener('keydown', this.handleNavPostEvent);
		this.widgetInstance.click(this.content, '.ti-nav-arrow > *', this.handleNavPostEvent);

		this.widgetInstance.initSwipe(
			this.content,
			'.ti-card-media',
			(slider, startX, moveX) => {
				let isNext = startX > moveX;

				slider = 'scroller' === this.widgetInstance.data.style.lightbox.type ?
						// scroller may has inner carousel arrows on multiple cards
						slider.querySelector('[data-behaviour="slide"]') :
						// in other case there is only one card in the lightbox at once with no related arrow control to the inner carousel
						this.content.querySelector('.ti-card-media [data-behaviour="slide"]')

				this.getNextPost(slider, isNext);
			}
		);

		// lightbox close event
		this.widgetInstance.click(this.content, '.ti-lightbox, .ti-lightbox *:not(.ti-lightbox-inner *, .ti-nav-arrow *)', (event) => this.close());

		// video control
		this.widgetInstance.click(this.content, 'video', (event) => {
			event.preventDefault();
			const video = event.target;
			if (video.paused) {
				video.play();
			} else {
				video.pause();
			}
		});

		// embed media control
		this.widgetInstance.click(this.content, 'img[data-embed-media]', (event) => {
			event.preventDefault();
				const thumbnail = event.target;
				this.content.querySelectorAll('img[data-embed-media] ~ iframe').forEach((embedMedia) => embedMedia.remove());
				this.widgetInstance.buildIframe(thumbnail);
		});

		// trigger event
		document.dispatchEvent(new Event('trustindex-feed-lightbox-open'));
	}

	close() {
		// on close remove the lightbox
		console.log('[Trustindex Feed] close lightbox');

		TrustindexFeed.getTargetDocument().body.removeChild(this.content);

		// remove event to get next post
		TrustindexFeed.getTargetWindow().removeEventListener('keydown', this.handleNavPostEvent);

		this.content = null;
	}

	navPostEvent(event) {
		let isNext = true;

		if ('keydown' === event.type) {
			if (['ArrowLeft', 'ArrowRight'].includes(event.key) && ['carousel','media'].includes(this.widgetInstance.data.style.lightbox.type)) {
				isNext = 'ArrowRight' === event.key;
			} else if ('Escape' === event.key) {
				// close on escape
				return this.close();
			} else {
				return;
			}

		} else if ('click' === event.type) {
			// slide by click
			if (event.target.matches('.ti-nav-arrow > *')) {
				isNext = event.target.classList.contains('ti-arrow-next');
			} else {
				return;
			}
		}

		// lightbox slides the inner carousel album first. If it is ended go to the next post.
		const slider = 'scroller' === this.widgetInstance.data.style.lightbox.type
						// scroller may has inner carousel arrows on multiple cards
						? event.target.closest('.ti-card-media').querySelector('[data-behaviour="slide"]')
						// in other case there is only one card in the lightbox at once with no related arrow control to the inner carousel
						: this.content.querySelector('.ti-card-media [data-behaviour="slide"]');

		this.getNextPost(slider, isNext);
	}

	getNextPost(slider, isNext) {
		if (slider && this.widgetInstance.sliderHasNext(slider, isNext)) {
			this.widgetInstance.slideFeed(slider, isNext, true, 0);
		} else {
			// go to the next post
			let upcomingPostId = this.siblings[isNext ? 'next' : 'prev'];

			if (upcomingPostId) {
				let post = this.widgetInstance.getPosts().find((post) => post['id'] === upcomingPostId),
					postIndex = 0;

				if (post) {
					console.log('[Trustindex Feed] upcoming post into ligthbox', post);

					// if the previous post is a carousel album than slide to the last slide on lightbox open
					if (!isNext) {
						postIndex = post.media_content.filter((media) => 'has_more' !== media.media_type).length - 1;
					}
					this.open(upcomingPostId, postIndex);
				}
			} else {
				console.log('[Trustindex Feed] no upcoming post into lightbox');
			}
		}
	}
}