export default class TrustindexDateFormater {
	constructor(timestamp, language, relativeTime) {
		this.instance = new Date(timestamp);
		this.language = language;
		this.relativeTime = relativeTime;
		this.get = this.getFunctions();
	}

	format(format) {
		for (const dateConst in this.get) {
			let pattern = new RegExp('\\b(' + dateConst + ')\\b', 'g');
			if (format.match(pattern)) {
				format = format.replace(pattern, this.get[dateConst]());
			}
		}

		return format;
	}

	getFunctions() {
		return {
			// days (2 digits with leading zeros)
			'd': () => String(this.instance.getDate()).padStart(2, '0'),
			// days (without leading zeros)
			'j': () => String(this.instance.getDate()),
			// month (numeric representation)
			'mm': () => String(this.instance.getMonth() + 1).padStart(2, '0'),
			// month (full textual representation)
			'mmmm': () => {
				let lang = (this.language || navigator.language || navigator.browserLanguage || (navigator.languages || ['en'])[0]),
					month = this.instance.toLocaleString(lang, {
						month: 'long'
					});

				return month.charAt(0).toUpperCase() + month.slice(1);
			},
			// year
			'yyyy': () => this.instance.getFullYear(),
			// relative time
			'x days ago': () => TrustindexFeed.getRelativeTime((this.instance.getTime() / 1000), this.relativeTime),
			'hide': () => '',
		};
	}
}