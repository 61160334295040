export default class TrustindexFeedDataLoader {
	static loadData(widgetInstance, callback) {
		if ('string' !== typeof widgetInstance.getKey()) {
			console.log('[Trustindex Feed] the feed data key is invalid.');

			return false;
		}

		let json = {};
		if (!widgetInstance.data && (json = widgetInstance.getJsonLd())) {
			widgetInstance.data = json.data;
		}

		if (widgetInstance.data) {
			if (callback) {
				callback();
			}

			return true;
		}

		fetch(widgetInstance.getWidgetDataUrl())
			.then((res) => {
				if (!res.ok) {
					throw new Error(res.status);
				}

				return res.json();
			})
			.then((data) => {
				widgetInstance.data = data;

				console.log('[Trustindex Feed] loaded data from json file');

				if (callback) {
					callback();
				}
			})
			.catch((error) => {
				// TODO: delete after all feed widgets regenerated
				// try to get data from js object
				let dataKey = 'ti_' + widgetInstance.getKey();
				let data = null, attempts = 0;

				if (eval('typeof ' + dataKey) == 'undefined') {
					// load data from js
					script.after(TrustindexWidgetBuilder.createElement({
						node: 'script',
						attr: {
							class: 'feed-data',
							src: widgetInstance.getWidgetDataUrl().replace('.json', '.js')
						}
					}));
				}

				// the js data object may load dynamically.. wait till the data is accessible
				let dataCheckInterval = setInterval(() => {
					try {
						data = eval(dataKey)
					} catch (error) {
						// it is not passed by object from dom
					}

					attempts++;

					if (data) {
						clearInterval(dataCheckInterval);

						widgetInstance.data = data,

						console.log('[Trustindex Feed] loaded data from js script');

						if (callback) {
							callback();
						}
					} else if (attempts > 1000) {
						// avoid infinite loop if data not accessible
						clearInterval(dataCheckInterval);

						console.error('[Trustindex Feed] failed to load data from: ', dataKey);

						widgetInstance.data = {'error': 'failed-to-load'};
					}
				}, 1);
			}
		);
	}
}