import TrustindexFeed from './components/TrustindexFeed.js';
import TrustindexWidgetBuilderModule from './components/TrustindexBuilderModule.js';


if ('undefined' === typeof window.TrustindexFeed) {
	window.tiFeedWidgetInstances = [];
	window.TrustindexFeed = TrustindexFeed;
	window.TrustindexWidgetBuilder = new TrustindexWidgetBuilderModule(TrustindexFeed.isAdminEditUrl());

	window.TrustindexFeed.setConstants();
} else {
	console.log('[Trustindex Feed] already loaded.');
}

TrustindexFeed.init();