export default class TrustindexFeedUrlParser {
	static getUrlTemplates(sourceType) {
		const urlTemplates = {
			'Twitter': {
				mention: 'https://twitter.com/$1',
				hashtag: 'https://twitter.com/hashtag/$1',
			},
			'Instagram': {
				mention: 'https://www.instagram.com/$1',
				hashtag: 'https://www.instagram.com/explore/tags/$1',
			},
			'Facebook': {
				mention: 'https://www.facebook.com/$1',
				hashtag: 'https://www.facebook.com/hashtag/$1',
			},
			'Tiktok': {
				mention: 'https://www.tiktok.com/@$1',
				hashtag: 'https://www.tiktok.com/tag/$1',
			},
			'Youtube': {
				mention: 'https://www.youtube.com/@$1',
				hashtag: 'https://www.youtube.com/hashtag/$1',
			},
			'Pinterest': {
				mention: 'https://pinterest.com/$1',
				hashtag: 'https://pinterest.com/search/pins/?q=$1',
			},
			'Linkedin': {
				mention: 'https://www.linkedin.com/company/$1',
				hashtag: 'https://www.linkedin.com/feed/hashtag/?keywords=$1',
			},
		};

		return urlTemplates[sourceType] || null;
	}

	static getLinkPatterns() {
		return {
			link: /\bhttps?:\/\/\S+|www\.\S+\.\S+\b/gi,
			mailto: /\bmailto:([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})\b/gi,
			tel: /\btel:(\+?[0-9]+(?:-[0-9]+)*)\b/gi,
			mention: /@([^@\s]+)/gi,
			hashtag: /#([^#\s]+)/gi,
		};
	}

	static parse(text, sourceType) {
		if ('string' !== typeof text) {
			return text;
		}

		let baseUrls = this.getUrlTemplates(sourceType) || {};

		for (const [ref, regex] of Object.entries(this.getLinkPatterns())) {
			let url = (baseUrls[ref] || '$&'),
				content = ['tel','mailto'].includes(ref) ? '$1' : '$&';
			text = text.replace(regex, '<a href="' + url + '" target="_blank">' + content + '</a>');
		}

		return text;
	}
}