import TrustindexFeedWidget from './TrustindexFeedWidget.js';
import TrustindexCommon from '../../TrustindexCommon.js';

export default class TrustindexFeed extends TrustindexCommon {
	static setConstants() {
		this.setCommonConstants();
	}

	static init() {
		// run after window loads
		if (!document.body) {
			window.onload = TrustindexFeed.init;

			return;
		}

		console.log('[Trustindex Feed] JS init...');

		// load contents from script
		let scripts = document.querySelectorAll(TrustindexFeed.getScriptSelector('loader-feed'));
		console.log('[Trustindex Feed] scripts:', scripts);

		scripts.forEach(scriptElement => {
			let key = TrustindexFeed.getWidgetKey(scriptElement);

			if (!key || key.search('=') != -1) {
				return console.log('[Trustindex Feed] key not found.');
			}

			console.log('[Trustindex Feed] key (' + key + ') found.');

			if (scriptElement.getAttribute('data-ti-inited')) {
				return console.log('[Trustindex Feed] content already inited.');
			}

			const instance = new TrustindexFeedWidget(scriptElement, key);

			instance.scriptTag.setAttribute('data-ti-inited', true);
			instance.isWordpress = TrustindexFeed.isWordpress(key);

			if (TrustindexFeed.isAdminUrl()) {
				// no css load on admin
				instance.init();
			} else {
				// load style from css
				let cssUrl = instance.getWidgetStyleUrl();
				console.log('[Trustindex Feed] load css...', cssUrl);
				TrustindexFeed.addCSS(
					cssUrl,
					() => instance.init(),
					false,
					() => {
						// in wordpress the css may load from link
						if (instance.isWordpress) {
							instance.init();
						}
					}
				);
			}

			window.tiFeedWidgetInstances.push(instance);
		});
	}

	static getWidgetKey(scriptElement) {
		let attr = 'src';
		if (scriptElement.getAttribute('data-src')) {
			attr = 'data-src';
		}

		let url = scriptElement.getAttribute(attr);

		// get key
		let key = url.split('?');
		if (key.length < 2) {
			return null;
		}

		key = key.pop();

		// cut extra params. ie.: &_234234=12313
		key = key.split('&').shift();

		// cut "ver=" and plugin version number (wordpress add this)
		key = key.replaceAll(/(\d+\.\d+\.\d+)|(ver=)/g, '');

		return key;
	}

	static isWordpress(key) {
		let isWordpress = false;
		if (-1 !== key.search('|')) {
			let tmp = key.split('|');
			key = tmp.shift();

			isWordpress = tmp.join('|') === 'wordpress' || (TrustindexFeed.isAdminUrl() && -1 !== location.href.indexOf('iframe/1'));
		}

		return isWordpress;
	}

	static getTargetWindow() {
		return TrustindexFeed.isCrossDomainIframe()
				? window.parent
				: window;
	}

	static getTargetDocument() {
		return TrustindexFeed.isCrossDomainIframe()
				? window.parent.document
				: window.document;
	}
}