import TrustindexFeedDataLoader from './TrustindexFeedDataLoader';
import TrustindexFeedMasonryModule from './TrustindexFeedMasonryModule';
import TrustindexFeedLightboxModule from './TrustindexFeedLightboxModule';
import TrustindexFeedUrlParser from './TrustindexFeedUrlParser';
import TrustindexDateFormater from './TrustindexDateFormater';

export default class TrustindexFeedWidget {
	constructor(scriptTag, key) {
		this.scriptTag = scriptTag;

		this.content = null;

		this.runningIntervals = [];

		this.isWordpress = false;

		this.dataKey = key;

		this.data = null;

		this.authorId = null;

		this.tmpPosts = {};

		this.lightbox = new TrustindexFeedLightboxModule(this);
	}

	getKey() {
		return this.dataKey;
	}

	setKey(key) {
		this.dataKey = key;
	}

	getWKey() {
		let key = this.data['widget-key'];

		if (undefined === key) {
			key = this.getKey();
		}

		return key + '';
	}

	getWidgetDir() {
		return TrustindexFeed.getCDNUrl() + 'widgets/' + this.getKey().substring(0, 2) + '/' + this.getKey() + '/';
	}

	getWidgetDataUrl() {
		return this.getWidgetDir() + 'data.json';
	}

	getWidgetStyleUrl() {
		let json = this.getJsonLd();
		if (this.isWordpress && 'undefined' !== typeof json.cssUrl) {
			return json.cssUrl;
		}

		return this.getWidgetDir() + 'style.css';
	}

	getJsonLd() {
		if ('application/ld+json' === this.scriptTag.getAttribute('type')) {
			return JSON.parse(this.scriptTag.innerHTML);
		}

		return null;
	}

	init(authorId) {
		if (this.scriptTag.getAttribute('data-ti-loaded') || this.scriptTag.getAttribute('data-ti-loading')) {
			// prevent parallel initialization
			return;
		}

		this.authorId = ('undefined' !== typeof authorId && 'summary' !== authorId ? authorId : null);
		this.scriptTag.setAttribute('data-ti-loading', true);

		if (!this.content) {
			let feedElementContainer = this.scriptTag;
			let json = this.getJsonLd();
			if (json) {
				// there is a div container in the DOM
				if ('undefined' !== typeof json.container && document.getElementById(json.container)) {
					feedElementContainer = document.getElementById(json.container);
				} else if (this.isWordpress && !TrustindexFeed.isAdminUrl()) {
					return console.error('[Trustindex Feed] Wordpress target container is missing!');
				}
			}

			// do not build on the edit page for the first load
			if (!TrustindexFeed.isAdminEditUrl() || window.FeedEdit) {
				// echo loading
				this.content = TrustindexWidgetBuilder.buildContentHTML(TrustindexWidgetBuilder.createElement({}), [this.translate('loading') + '...']);

				// put feed in the DOM
				feedElementContainer.after(this.content);
			}
		}

		this.load(() => {
			// build the widget
			this.content.replaceWith(this.build());

			this.content.style.visibility = 'hidden';
			this.refreshBody(() => {
				// widget is loaded
				this.content.style.visibility = '';

				// trigger events when the feed is loaded
				this.scriptTag.setAttribute('data-ti-loaded', true);
				document.dispatchEvent(new CustomEvent('trustindex-feed-loaded', {
					detail: { feedInstance: this }
				}));

				console.log('[Trustindex Feed] building finished');
				console.timeEnd('[Trustindex Feed] building progress');
			});

			this.registerEvents();
		});
	}

	load(callback) {
		// load data
		TrustindexFeedDataLoader.loadData(this, () => {
			this.scriptTag.removeAttribute('data-ti-loading');

			if (this.data.error) {
				return this.content.replaceWith(this.buildError());
			}

			// do not build on the edit page for the first load
			if (TrustindexFeed.isAdminEditUrl() && !window.FeedEdit) {
				return document.dispatchEvent(new CustomEvent('trustindex-feed-loaded', {
					detail: { feedInstance: this }
				}));
			}

			if ('function' === typeof callback) {
				callback();
			}
		});
	}

	destroy() {
		this.stopAutoslide();

		// remove content
		if (this.content) {
			this.content.remove();
		}
		this.content = null;
		this.tmpPosts = {};
		// remove script loaded
		this.scriptTag.removeAttribute('data-ti-loaded');
		console.log('[Trustindex Feed] instance is destroyed.');
	}

	refreshBody(callback) {
		// remove old body
		this.stopAutoslide();
		this.tmpPosts = {};
		this.content.querySelector('main.ti-widget-body').innerHTML = '';
		this.destoryMasonry();

		// remove loadmore from footer
		let loadmore = this.content.querySelector('nav.ti-nav-loadmore');
		if (loadmore) {
			loadmore.remove();
		}

		// settings when the widget become visible
		this.setWidth();
		this.setAutoColWidth();

		// after the widget skeleton is visible and the size is calculated, render the widget body
		this.buildBody();

		console.log('[Trustindex Feed] init finished.');

		let autoplay = {
			'widget': this.data.style.autoplay_widget,
			'widget-card': this.data.style.autoplay_widget_card,
		};

		if (autoplay) {
			Object.entries(autoplay).forEach(([type, data]) => {
				if ('true' === data.enabled) {
					let sliders = this.content.querySelectorAll('.ti-widget [data-behaviour="slide"][data-target="' + type + '"]');
					if (sliders) {
						sliders.forEach((slider) => {
							if (('widget' === type || slider.children.length > 1) && !slider.intervalPointer) {
								this.setAutoslide(slider, data.interval);
							}
						});
					}
				}
			});
		}

		// init text overflows
		this.initTextOverflow();

		this.initMasonry();

		this.loadImg(this.content, () => this.masonry?.refresh());

		if ('function' === typeof callback) {
			callback();
		}
	}

	resize(isForced) {
		this.destoryMasonry();

		// resize only when width changed
		if (window.oldWidth !== window.innerWidth || isForced) {
			window.oldWidth = window.innerWidth;

			console.log('[Trustindex Feed] resize.');
			this.setWidth();
			this.setAutoColWidth();
			this.loadImg(this.content);

			// on slider / loadmore with dynamic columns the resize may recalculates the capacity without update its elements' count... refresh the conainer by widget body reload
			if ('true' === this.data.style.layout.cols_num_auto && ('slider' === this.data.style.layout.type || 'true' === this.data.style.layout.loadmore)) {
				let capacity = parseInt(this.data.style.layout.cols_num) * parseInt(this.data.style.layout.rows_num);
				let visibleCardCount = this.content.querySelectorAll('.ti-layout-item').length;
				if ('slider' === this.data.style.layout.type) {
					visibleCardCount--;
				}
				// detect if the container capacity is changed
				if (capacity !== visibleCardCount) {
					this.refreshBody();

					return;
				}
			}

			// reposizion of the feed slides
			this.content.querySelectorAll('[data-behaviour="slide"]').forEach((slider) => {
				// slider is on the starting state - no resize effect
				if (!slider.state) {
					return false;
				}

				// slide the current slide from the last movement target postition to the new slide position by the width
				let movedFrom, movedTo;
				[movedFrom, movedTo] = slider.lastMovement;

				this.slide(slider, [movedTo, (slider.state * slider.clientWidth) + 'px'], 300);
			});
		}

		this.initTextOverflow();
		this.initMasonry();
	}

	setWidth() {
		// set widget style breakpoint dynamically
		if (this.content && this.content.offsetWidth > 0) {
			let width = this.content.offsetWidth;

			this.content.dataset.widgetWidth = (width < 576 ? 'xs' : (width < 768 ? 'sm' : 'lg'));
		}
	}

	setAutoColWidth() {
		// set auto cols num dynamically (list has no this option)
		if (
			'true' === this.data.style.layout.cols_num_auto
			&& ['slider','grid','masonry'].includes(this.data.style.layout.type)
		) {
			let fullWidth = this.content.querySelector('.ti-widget-body').offsetWidth,
				minWidth = parseInt(this.data.style.layout.target_col_width);

			if (fullWidth) {
				let colsNum = Math.floor(fullWidth / minWidth) || 1;
				console.log('[Trustindex Feed] set dynamic cols...', ' widget_width:' + fullWidth, ' col_min_width:'+minWidth, ' cols_num:'+colsNum);
				// set dynamic cols number
				this.data.style.layout.cols_num = colsNum;
				this.content.dataset.col = this.data.style.layout.cols_num;
			}
		}
	}

	// builder functions
	build() {
		console.log('[Trustindex Feed] building content...');
		console.time('[Trustindex Feed] building progress');
		this.content = TrustindexWidgetBuilder.createElement({
			node: '.ti-widget.ti-feed-widget',
			attr: {
				'data-source': this.data.source_types,
				'data-widget-type': 'social',
				'data-layout': this.data.style.layout.type,
				'data-col': 'list' === this.data.style.layout.type ? '1' : this.data.style.layout.cols_num,
				'data-style': this.data.style.type,
				'data-click': this.data.style.card.click_action,
				'data-wkey': this.getWKey(),
			}
		});

		this.buildHeader();
		// body will build right after the widget become visible... now add an empty widget body
		// this.buildBody();
		TrustindexWidgetBuilder.buildContentHTML(this.content, [{ node: 'main.ti-widget-body' }]);
		this.buildFooter();

		return this.content;
	}

	buildHeader() {
		console.log('[Trustindex Feed] build widget header...');

		let sources = this.data.sources,
			contentList = [],
			profiles = [];

		// the summary is need to be on the first position
		let authorIds = Object.keys(sources);
		if (authorIds.includes('summary')) {
			authorIds = ['summary', ...authorIds.filter((key) => 'summary' !== key)];
		}

		for (const authorId of authorIds) {
			let source = sources[authorId];
			contentList.push({ // header .ti-widget-header
					node: '.ti-widget-header',
					attr: {
						'data-id': authorId,
						'class': this.authorId && this.authorId === authorId ? 'ti-active' : '',
						'data-source': source.type.split(' ')[0],
					},
					content: [
						{ // .ti-profile
							node: '.ti-profile',
							content: this.buildProfileDetails({
								name: this.data.sources[authorId].user.author_name,
								fullName: this.data.sources[authorId].user.author_full_name,
								nameAttr: {
									'data-target': 'profile-switch',
								},
								text: TrustindexFeedUrlParser.parse(this.data.sources[authorId].user.author_bio, source.type),
								avatarUrl: this.data.sources[authorId].user.avatar_url,
								sourceType: this.data.sources[authorId].type,
								authorId: authorId,
								type: 'header',
							})
						}, // .ti-profile
						{
							node: '.ti-header-btn',
							attr: {
								'_show': this.data.style.header.show_follow_button
							},
							content: (() => {
								let url = this.data.sources[authorId].user.profile_url;

								if ('object' === typeof url) {
									// summary header with multiple profiles
									return [{
											node: 'a.ti-btn.summary',
											attr: {
												'data-target': 'link-tree'
											},
											content: [{
													node: 'i.ti-icon'
												},
												'Youtube' === this.data.source_types ? this.translate('My channel') : this.translate('Follow'),
											],
										},
										this.buildDropdownList('link-tree', url)
									]
								} else {
									return {
										node: 'a.ti-btn',
										attr: {
											'href': url,
											'target': '_blank',
										},
										content: [{
												node: 'i.ti-icon'
											},
											'Youtube' === source.type ? this.translate('My channel') : this.translate('Follow'),
										]
									}
								}
							})()
						}
					]
				} // header .ti-widget-header
			);

			// set up profile switch to different source
			if ((!this.authorId && 'summary' !== authorId) || (this.authorId && this.authorId !== authorId)) {
				profiles.push({
					type: this.data.sources[authorId].type,
					name: this.data.sources[authorId].user.author_name,
					url: authorId,
				});
			}
		}

		let isMultiSourceHeader = profiles.length > 1;

		if (isMultiSourceHeader) {
			contentList.push(this.buildDropdownList('profile-switch', profiles));
		}

		if (!this.authorId) {
			contentList[0]['attr']['class'] = 'ti-active';
		}

		TrustindexWidgetBuilder.buildContentHTML(this.content, [{
			node: 'header.ti-header',
			attr: {
				'_show': this.data.style.header.enabled,
				'data-header-type': this.data.style.header.type,
				'data-header-switch': String('true' === this.data.style.header.switch && isMultiSourceHeader),
			},
			content: contentList
		}]);
	}

	buildBody() {
		console.log('[Trustindex Feed] build widget body...');

		// add posts
		let postItems = this.buildCardCollection();

		if ('masonry' === this.data.style.layout.type) {
			postItems.unshift({
				node: '.ti-grid-sizer'
			});
		}

		let bodyContent = [
			{
				node: '.ti-layout-container',
				attr: 'slider' === this.data.style.layout.type ?
					{
						'data-behaviour': 'slide',
						'data-target': 'widget'
					} :
					{},
				content: {
					node: 'section.ti-widget-layout',
					content: postItems
				},
			}
		];

		if (this.hasMorePosts()) {
			// add nav arrows only if necessary - is slider includes all the posts
			if ('slider' === this.data.style.layout.type) {
				bodyContent.push(this.buildNavArrows(this.data.style.arrow.type));
			}
			// add load more only if necessary
			else if(['grid','list','masonry'].includes(this.data.style.layout.type)
					&& 'true' === this.data.style.layout.loadmore)
			{
				TrustindexWidgetBuilder.buildContentHTML(
					this.content.querySelector('footer.ti-widget-footer'),
					[{
						node: 'nav.ti-nav-loadmore',
						content: {
							node: '.ti-btn',
							content: this.translate('Load more'),
						}
					}]
				);
			}
		}

		// set up the .ti-widget-layout frame
		TrustindexWidgetBuilder.buildContentHTML(this.content.querySelector('main.ti-widget-body'), bodyContent);

		if (this.hasMorePosts() && 'slider' === this.data.style.layout.type) {
			this.renderSlide();
		}
	}

	buildFooter() {
		console.log('[Trustindex Feed] build widget footer...');
		let footerSchema = {
			node: 'footer.ti-widget-footer',
			content: []
		};

		let postCount = this.getPosts().length;
		let colsNum = 'list' === this.data.style.layout.type ? 1 : parseInt(this.data.style.layout.cols_num);
		let rowsNum = parseInt(this.data.style.layout.rows_num);
		let pages = 'slider' === this.data.style.layout.type
						? Math.ceil(postCount / (colsNum * rowsNum))
						: 1;

		footerSchema.content.push(this.buildNavDots(pages));

		TrustindexWidgetBuilder.buildContentHTML(this.content, [footerSchema]);
	}

	buildError() {
		console.log('[Trustindex Feed] build error...');
		let errorMessage;
		switch (this.data.error) {
			default:
			case 'limited':
				errorMessage = [
					this.translate('Unfortunately, the 7-day trial period has expired.'),
					{
						node: 'strong',
						content: {
							node: 'a',
							attr: {
								href: 'https://admin.trustindex.io/subscription/details',
								target: '_blank',
							},
							content: this.translate('Check our subscription plans!') + ' &gt;&gt;'
						}
					}
				];
				break;
			case 'filter-no-result':
			case 'failed-to-load':
				errorMessage = [this.translate('Failed to load data!')];
				break;
		}

		this.content = TrustindexWidgetBuilder.buildContentHTML(
			null,
			[
				{
					attr: {
						style: 'border: 4px dashed red; border-radius: 20px; padding: 20px; background: white; font-family: monospace; color:#000000;'
					},
					content: [
						{
							node: 'img',
							attr: {
								src: TrustindexFeed.getCDNUrl() + 'assets/platform/Trustindex/logo.svg',
								alt: 'Trustindex',
								style: 'height: 20px;'
							}
						},
						{
							node: 'br'
						},
						...errorMessage
					]
				}
			]
		);

		document.dispatchEvent(new CustomEvent('trustindex-feed-load-failed', {
			detail: {
				'feedInstance': this,
				'type': this.data.error,
			},
		}));

		return this.content;
	}

	// components initialization
	buildPostMedia(post, type) {
		if ('text' === post.type) {
			return {};
		}

		let postMedia = {
			node: '.ti-card-media',
			attr: {
				'_show': this.data.style.card.show_post_media
			},
			content: []
		}

		if ('post' === type) {
			// only post type media has hover animation and media icon (the lightbox don't)
			postMedia.content.push(
				{
					node: '.ti-media-icon' + ('album' === post.type ? '.ti-photos-icon' : ('video' === post.type ? '.ti-videos-icon' : '')),
					attr: {
						'_show': this.data.style.card.show_media_icon
					}
				}
			);
		} else if ('lightbox' === type) {
			// lightbox has only inner arrow like carousel album
			if ('album' !== post.type && ['carousel','media'].includes(this.data.style.lightbox.type)) {
				postMedia.content.push(
					// lightbox has no arrow-type.. default uses the big circle arrow
					this.buildNavArrows(null)
				);
			}
		}

		if ('album' === post.type) {
			if ('post' === type && 'grid' === this.data.style.card.media_layout) {
				// grid album
				postMedia.node += '.ti-grid-type';

				let album = {
					node: '.ti-card-grid',
					content: [{
							node: '.ti-grid-col',
							content: []
						},
						{
							node: '.ti-grid-col',
							content: []
						},
					]
				}

				// put media items into grid (max number of media is 4)
				let mediaGrid = post.media_content.slice();
				// if the media is not prelimited, limit it to 4
				if ('undefined' !== typeof mediaGrid[4] && 'has_more' !== mediaGrid[4].media_type) {
					mediaGrid[4] = {
						media_type: 'has_more',
						media_left: mediaGrid.slice(5).length
					};
					mediaGrid = mediaGrid.slice(0, 5);
				}

				for (let index = 0; index < mediaGrid.length; index++) {
					let media = mediaGrid[index],
						gridCol = (index % 2),
						gridItem = {
							node: '.ti-grid-item',
							content: this.buildMedia(media, type, post)
						};
					// check the next item is the "has more" ending item and append the current grid item with it
					if ('undefined' !== typeof mediaGrid[index + 1] && 'has_more' === mediaGrid[index + 1].media_type) {
						index++;
						gridItem.content.push(...this.buildMedia(mediaGrid[index], type, post));
					}
					album.content[gridCol].content.push(gridItem);
				}

				postMedia.content.push(album);
			} else {
				// slider album
				let album = {
					node: '.ti-card-slider',
					attr: {
						'data-behaviour': 'slide',
						'data-target': 'widget-card'
					},
					content: []
				}

				post.media_content.forEach((media) => {
					album.content.push({
						node: '.ti-slider-item',
						content: this.buildMedia(media, type, post)
					})
				});

				postMedia.content.push(...[
					album,
					this.buildNavArrows(this.data.style.carousel_album_arrow.type),
					this.buildNavDots(post.media_content.length),
					{
						node: '.ti-image-counter',
						content: '1/' + post.media_content.length
					},
				]);
			}
		} else if (['image', 'video'].includes(post.type)) {
			postMedia.content.push(...this.buildMedia(post.media_content[0], type, post));
		}

		return postMedia;
	}

	buildMedia(media, type, post) {
		let mediaContent = [],
			alt = 'Trustindex feed '+media.media_type+', shared by '+post.author_name+' on '+post.source_type+' on '+(new TrustindexDateFormater(post.created_at, 'en')).format('mmmm d, yyyy.');
		if ('lightbox' === type && 'video' === media.media_type) {
			console.log('[Trustindex Feed] set up video player for', media.video_url);

			if (['Tiktok','Youtube','Vimeo'].includes(post.source_type)) {
				let imageNode = this.buildImageNode(media, alt),
					isAutoLoaded = ['Youtube','Vimeo'].includes(post.source_type);
				imageNode.attr['data-embed-media'] = media.video_url;
				if (media.aspect_ratio) {
					imageNode.attr['data-aspect-ratio'] = media.aspect_ratio;
				}

				if (isAutoLoaded) {
					imageNode.onload = (event) => this.buildIframe(event.target);
				}

				mediaContent.push(imageNode);

				if (!isAutoLoaded) {
					mediaContent.push({
						node: '.ti-media-control',
						attr: {
							'data-action': 'play',
						}
					});
				}
			} else {
				mediaContent.push({
					node: 'video',
					content: [{
							node: 'source',
							attr: {
								src: media.video_url
							}
						},
						this.translate('This browser does not support video playback!'),
					]
				});
			}
		} else if ('has_more' === media.media_type) {
			// media count left on album posts
			mediaContent.push({
				node: '.ti-has-more',
				content: String(parseInt(media.media_left) + 1)
			});
		} else {
			mediaContent.push(this.buildImageNode(media, alt));
		}

		return mediaContent;
	}

	buildPostContent(post, type) {
		// set card header to post content
		let content,
			text = this.buildDate(post.created_at, this.data.style['post' === type ? 'card' : type].show_date);

		content = [
			this.buildCardHeader({
				name: this.data.sources[post.author_id].user.author_name,
				fullName: this.data.sources[post.author_id].user.author_full_name,
				text: text,
				title: post.title || null,
				avatarUrl: post.author_img,
				sourceType: post.source_type,
				type: ('post' === type ? 'card' : type),
			})
		];

		if ('post' === type) {
			let metrics = [];
			// add like numbers
			if (!isNaN(post.like_count)) {
				metrics.push({ // .ti-post-likes
						node: '.ti-post-likes',
						attr: {
							'_show': String('true' === this.data.style.card.show_like_num)
						},
						content: {
							node: 'span',
							content: this.roundNumber(post.like_count ? String(post.like_count) : '0')
						}
					}, // .ti-post-likes
				)
			}
			// add comment numbers
			if (!isNaN(post.comment_count)) {
				metrics.push({ // .ti-post-comment
						node: '.ti-post-comments',
						attr: {
							'_show': String('true' === this.data.style.card.show_comment_num)
						},
						content: {
							node: 'span',
							content: this.roundNumber(post.comment_count ? String(post.comment_count) : '0')
						}
					},	// .ti-post-comment
				);
			}
			// add repost numbers (only on Twitter and Facebook)
			if (['Twitter', 'Facebook'].includes(post.source_type) && isNaN(post.repost_count)) {
				metrics.push({ // .ti-post-repost
						node: '.ti-post-repost',
						attr: {
							'_show': String('true' === this.data.style.card.show_repost_num && '?' !== post.repost_count)
						},
						content: {
							node: 'span',
							content: this.roundNumber(post.repost_count ? String(post.repost_count) : '0')
						}
					}, // .ti-post-repost
				);
			}
			// add action buttons
			metrics.push({
				node: '.ti-post-meta-actions',
				content: [{
						node: '.ti-post-view-btn',
						content: {
							node: 'a',
							attr: {
								href: post.url,
								target: '_blank'
							},
							content: this.translate('View'),
						}
					}
					// {
					// 	node: '.ti-post-share-btn',
					// 	content: {
					// 		node: 'a',
					// 		attr: {
					// 			href: post.url,
					// 			target: '_blank'
					// 		}
					// 		content: this.translate('Share'),
					// 	}
					// }
				]
			});

			if (Object.keys(metrics).length) {
				content.push({ // .ti-post-meta
						node: '.ti-post-meta',
						content: metrics
					}  // .ti-post-meta
				);
			}

			if ('string' === typeof post.text && post.text.length > 0) {
				content.push({ // .ti-post-text
						node: '.ti-post-text',
						attr: {
							'_show': this.data.style.card.show_post_text
						},
						content: this.buildText(post)
					}, // .ti-post-text
				);
			}

		} else if ('lightbox' === type) {
			let metrics = [];
			// add like numbers
			if (!isNaN(post.like_count)) {
				metrics.push({	// .ti-post-likes
						node: '.ti-post-likes',
						attr: {
							'_show': String('true' === this.data.style.lightbox.show_like_num)
						},
						content: {
							node: 'span',
							content: this.translate('%number% likes', {'%number%': this.roundNumber(post.like_count ? post.like_count : '0')}),
						}
					},	// .ti-post-likes
				);
			}

			let scrollpaneContent = [];
			if (Object.keys(metrics).length) {
				scrollpaneContent.push({
					node: '.ti-post-meta',
					content: metrics
				});
			}

			// add text content if it has any
			if ('string' === typeof post.text && post.text.length > 0) {
				scrollpaneContent.push({
					node: '.ti-post-text',
					attr: {
						'_show': this.data.style.lightbox.show_post_text
					},
					content: this.buildText(post)
				});
			}

			// add comments if it has any
			if (post.comments && post.comments.length) {
				let commentList = [];
				post.comments.forEach((comment) => {
					commentList.push({
						node: '.ti-comment',
						content: [{
								node: '.ti-comment-meta',
								content: [{
										node: '.ti-commenter-name',
										content: comment.author_name
									},
									{
										node: '.ti-commment-date',
										content: this.buildDate(comment.created_at)
									}
								]
							},
							{
								node: '.ti-commment-text',
								content: TrustindexFeedUrlParser.parse(comment.text, post.source_type)
							},
						]
					})
				});

				scrollpaneContent.push({
					node: '.ti-comments',
					attr: {
						'_show': this.data.style.lightbox.show_comments
					},
					content: commentList
				});
			}

			content.push({ // .ti-post-scrollpane
					node: '.ti-post-scrollpane',
					content: scrollpaneContent
				} // .ti-post-scrollpane
			);
		}

		return { // .ti-post-content
			node: '.ti-post-content',
			content: content
		}; // .ti-post-content
	}

	roundNumber(number) {
		if (typeof number !== 'number') {
			number = parseInt(number);
		}

		if (isNaN(number)) {
			return null;
		}

		if (number < 1000) {
			return String(number);
		} else if (number < 10000) {
			return this.translate('%number%K', {'%number%': (number / 1000).toFixed(1)});
		} else if (number < 1000000) {
			return this.translate('%number%K', {'%number%': Math.round(number / 1000)});
		} else if (number < 10000000) {
			return this.translate('%number%M', {'%number%': (number / 1000000).toFixed(1)});
		} else {
			return this.translate('%number%M', {'%number%': Math.round(number / 1000000)});
		}
	}

	buildCard(post, type) {
		let content = [],
			cardType = this.data.style.card.type;

		if ('lightbox' === type) {
			// other card types has no lightbox view
			let cardTypes = ['3', '4', '5'];
			if (!cardTypes.includes(cardType)) {
				cardType = cardTypes.shift();
			}
		}

		if (post.repost_content && post.repost_content.length) {
			content.push({
				node: '.ti-reposted',
				content: {
					node: 'span',
					content: this.translate('%author% Retweeted', {'%author%': post.author_name}),
				}
			});

			// TODO repost with comment
			// content.push(
			// 	{	// main.ti-card-body
			// 		node: 'main.ti-card-body',
			// 		content: this.buildPostContent(post, type),
			// 	}	// main.ti-card-body
			// );

			// TODO multi-depth repost?
			post = post.repost_content[0];
		}

		if ('undefined' === typeof post.author_img  || !post.author_img) {
			let source = this.data.sources[post.author_id] || Object.values(this.data.sources)[0];
			post.author_img = source['user']['avatar_url'];
		}

		let bodyContent = [];

		bodyContent.push(this.buildPostMedia(post, type))

		bodyContent.push(this.buildPostContent(post, type))

		content.push({ // main.ti-card-body
				node: 'main.ti-card-body',
				content: bodyContent,
			} // main.ti-card-body
		);

		let mediaType = '';
		if ('album' === post.type) {
			mediaType = 'slider';
		} else if ('text' === post.type) {
			mediaType = 'no-media';
		}

		return {
			node: 'article.ti-widget-card',
			attr: {
				'data-source': post.source_type.split(' ')[0],
				'data-card-type': ('no-media' === mediaType ? '3' : cardType),
				'data-media-type': mediaType,
				'data-post-id': post.id,
				'data-url': post.url,
				'data-card-align': this.data.style.card.align,
				'data-card-ratio': !this.data.style.card.ratio ? 'square' : this.data.style.card.ratio,
			},
			content: content
		};
	}

	buildCardHeader({ name, fullName, text, title, avatarUrl, sourceType, type }) {
		return {
			node: 'header.ti-card-header',
			content: this.buildProfileDetails({
					'name': name,
					'fullName': fullName,
					'text': text,
					'title': title,
					'avatarUrl': avatarUrl,
					'sourceType': sourceType,
					'type': type,
				})
				.concat([{
					node: '.ti-platform'
				}])
		};
	}

	buildCardCollection(attrs) {
		// get the struct of the card collection to be rendered
		let capacity = null,
			postItems = [];
		if ('true' === this.data.style.layout.loadmore  || 'slider' === this.data.style.layout.type) {
			let colsNum = 'list' === this.data.style.layout.type ? 1 : parseInt(this.data.style.layout.cols_num);
			capacity = parseInt(this.data.style.layout.rows_num) * colsNum;
		}

		this.getPosts(capacity).forEach((post) => {
			console.log('[Trustindex Feed] add post: ' + post.id, post);
			postItems.push({
				node: '.ti-layout-item',
				attr: attrs,
				content: this.buildCard(post, 'post')
			});
		});

		return postItems;
	}

	buildIframe(thumbnail) {
		thumbnail.classList.add('ti-iframe-loading');
		let style = null,
		aspectRatio = null;
		if (thumbnail.dataset.aspectRatio) {
			aspectRatio = thumbnail.dataset.aspectRatio;
		} else if (thumbnail.naturalWidth && thumbnail.naturalHeight) {
			aspectRatio = thumbnail.naturalWidth +'/'+ thumbnail.naturalHeight;
		}
		console.log('[Trustindex Feed] iframe load: ' + thumbnail.dataset.embedMedia, aspectRatio);
		if (aspectRatio) {
			style = 'aspect-ratio:'+ aspectRatio +';';
			let [width,height] = aspectRatio.split('/');
			if (parseInt(width) < parseInt(height)) {
				style += 'width:auto;';
			} else {
				style += 'height:auto;';
			}
		}
		thumbnail.after(TrustindexWidgetBuilder.buildContentHTML(null, [{
				node: 'iframe',
				attr: {
					src: thumbnail.dataset.embedMedia,
					style: style,
					allow: 'accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture',
					referrerpolicy: 'strict-origin-when-cross-origin',
					},
				onload: function() {
					let iframe = this;
					setTimeout(() => {
							iframe.classList.add('ti-iframe-loaded');
							thumbnail.style.opacity = 0;
						}, 400);
					},
				}],
			)
		);
	}

	buildImageNode(media, alt) {
		let node = {
			node: 'img',
			attr: {
				'alt': alt,
				'data-src': media.image_url || '',
			}
		};

		// handle multiple resolutions
		if ('undefined' !== typeof media.image_urls) {
			node.attr['data-src-original'] = node.attr['data-src'];
			delete node.attr['data-src'];

			node.attr.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
			node.attr['data-src-small'] = media.image_urls['small'];
			node.attr['data-src-medium'] = media.image_urls['medium'];
			node.attr['data-src-large'] = media.image_urls['large'];
			node.attr['data-size'] = media.image_urls['size'];
		}

		return node;
	}

	// post functions
	getPosts(capacity) {
		// default capacity if not given is all posts count
		let all = false;
		if (!capacity && capacity != 0) {
			all = true;
			capacity = this.data.posts.length;
		}

		let hiddenPosts = Object.values(this.data.style.settings.hidden_posts || {});
		let posts = [];
		this.data.posts.forEach((post) => {
			if (
				(all || !this.tmpPosts[post.id])
				&& (!this.authorId || this.authorId === post.author_id)
				&& capacity > 0
				&& !hiddenPosts.includes(post.id)
			) {
				capacity--;
				posts.push(post);
				if (!all) {
					// tmp posts to detect repetition
					this.tmpPosts[post.id] = post;
				}
			}
		});

		// on infinity loop fill the empty post places in card collection
		if ('true' === this.data.style.layout.infinity_loop && capacity > 0) {
			this.tmpPosts = {};
			posts.push(...this.getPosts(capacity));
		}

		return posts;
	}

	hasMorePosts() {
		return 'true' === this.data.style.layout.infinity_loop
				|| Object.keys(this.tmpPosts).length < this.getPosts().length;
	}

	// text functions
	buildText(post) {
		let text = TrustindexFeedUrlParser.parse(post.text, post.source_type);

		return [
			// {
			// 	node: '.ti-poster-name',
			// 	attr: {
			// 		_show: this.data.style.card.show_username
			// 	},
			// 	content: {
			// 		node: 'a',
			// 		attr: {
			// 			href: post.url ?? this.data.sources[post.author_id].user.profile_url,
			// 			target: '_blank'
			// 		},
			// 		content: post.author_name
			// 	}
			// },
			text
		];
	}

	initTextOverflow(container) {
		TrustindexFeed.waitForVisibility(this.content, () => {
			// handle text overflows.. add "read more" if is too long
			// available only after the text is displayed
			if ('undefined' === typeof container) {
				container = this.content;
			}
			container.querySelectorAll('.ti-post-text').forEach((textContainer) => {
				const parent = textContainer.parentNode;
				const readMore = parent.querySelector('.ti-readmore');

				textContainer.dataset.initialHeight = textContainer.clientHeight;

				if (readMore) {
					// first remove the existing read more to redefine is it still necessary
					console.log('[Trustindex Feed] remove "read more" from ', textContainer);
					parent.removeChild(readMore);
				}

				if (textContainer.scrollHeight > textContainer.dataset.initialHeight) {
					console.log('[Trustindex Feed] add "read more" to ', textContainer);
					TrustindexWidgetBuilder.buildContentHTML(parent, [{
						node: '.ti-readmore',
						attr: {
							'data-open-text': this.translate('Read more'),
							'data-collapse-text': this.translate('Hide'),
						},
						content: this.translate('Read more'),
					}]);
				}
			});
		});
	}

	buildDate(timestamp, isShow) {
		let dateFormater = new TrustindexDateFormater(timestamp, this.data.language || 'en', this.translate('%d %s ago|today|day|days|week|weeks|month|months|year|years'));

		return {
			node: 'span.ti-date',
			attr: {
				'data-timestamp': timestamp,
				'_show': isShow
			},
			content: dateFormater.format(this.data.style.locales['date-format'])
		};
	}

	buildNavDots(count, activeIndex) {
		return {};
		// TODO temporary disabled dots
		// // set up pager dots
		// if ('undefined' === typeof activeIndex) {
		// 	activeIndex = 0;
		// }

		// let dotSchema = null;
		// if (count > 1) {
		// 	dotSchema = {
		// 		node: 'nav.ti-nav-dots',
		// 		content: [],
		// 	}

		// 	for (let i = 0; i < count; i++) {
		// 		dotSchema.content.push({
		// 			node: '.ti-dot' + (i === activeIndex ? '.ti-active' : '')
		// 		});
		// 	}
		// }

		// return dotSchema;
	}

	buildNavArrows(arrowType) {
		// set up pager arrows
		return {
			node: 'nav.ti-nav-arrow',
			attr: {
				'data-arrow-type': arrowType
			},
			content: [
				// default starts from left and no prev arrow
				// TODO comment out button arrows when plugin css can be refreshed
				{
					node: '.ti-arrow-prev',
					// node: 'button.ti-arrow-prev',
					attr: {
						'style': 'visibility:hidden;',
						// 'aria-label': 'Previous post',
					},
					// content: 'Previous'
				},
				{
					node: '.ti-arrow-next',
					// node: 'button.ti-arrow-next',
					// attr: {
					// 	'aria-label': 'Next post',
					// },
					// content: 'Next'
				}
			]
		}
	}

	buildProfileDetails({
		name,
		fullName,
		nameAttr,
		title,
		text,
		avatarUrl,
		sourceType,
		authorId,
		type
	}) {
		// set up avatar img with name and text
		if ('undefined' === typeof nameAttr) {
			nameAttr = {};
		}
		nameAttr['_show'] = String('true' === this.data.style[type].show_username || 'true' === this.data.style[type].show_full_name);

		let element = [
			{ // .ti-profile-details
				node: '.ti-profile-details',
				attr: {
					'data-show-username': String(Boolean('true' === this.data.style[type].show_username && name)),
					'data-show-full-name': String(Boolean('true' === this.data.style[type].show_full_name && fullName)),
				},
				content: [
					(
						['Youtube','Vimeo'].includes(sourceType) && title
							? {
								node: '.ti-post-title',
								attr: {
									'title': title,
									'_show': this.data.style[type].show_post_title,
								},
								content: TrustindexFeedUrlParser.parse(title, sourceType),
							}
							: {}
					),
					{ // .ti-name
						node: '.ti-name',
						attr: nameAttr,
						content: [
							(
								fullName
									? {
										node: '.ti-full-name',
										attr: {
											'_show': this.data.style[type].show_full_name,
										},
										content: fullName
									}
									: {}
							),
							{
								node: '.ti-username',
								attr: {
									'_show': String('true' === this.data.style[type].show_username || (!fullName && 'true' === this.data.style[type].show_full_name)),
								},
								content: name,
							},
						]
					}, // .ti-name
					(
						'card' === type
							? { // .ti-sub
									node: '.ti-sub',
									attr: {
										'_show': this.data.style[type].show_date
									},
									content: text
								} // .ti-sub
							: {}
					),
				]
			} // .ti-profile-details
		];

		if ('header' === type) {
			('1.1' <= this.data.style?.version ? element : element[0].content).push({ // .ti-header-numbers
					node: '.ti-header-numbers',
					content: (() => {
						// set up numbers in header
						let numbers = [];
						let numberDetails = {
							'posts': {
								'label':
									['Youtube','Vimeo'].includes(sourceType)
										? this.translate('%number% video', {'%number%': this.data.sources[authorId].user.post_num})
										: this.translate('%number% posts', {'%number%': this.data.sources[authorId].user.post_num})
									,
								'value': this.data.sources[authorId].user.post_num,
							},
							'followers': {
								'label': this.translate('%number% followers', {'%number%': this.data.sources[authorId].user.follower_num}),
								'value': this.data.sources[authorId].user.follower_num,
							},
							'follows': {
								'label': this.translate('%number% following', {'%number%': this.data.sources[authorId].user.follow_num}),
								'value': this.data.sources[authorId].user.follow_num,
							},
						};

						for (const [name, num] of Object.entries(numberDetails)) {
							let value = String(num.value);
							if (isNaN(value)) {
								continue;
							}

							numbers.push({
								node: '.ti-number',
								attr: {
									'data-num-of': name,
									'_show': this.data.style.header['show_' + name + '_number'],
								},
								content: num.label.split(' ').map((item) => {
									let elem = { content: item.toLowerCase() };
									if (!isNaN(item)) {
										elem.node = '.ti-value';
										elem.content = this.roundNumber(item);
									}

									return elem;
								}),
							});
						}

						return numbers;
					})()
				} // .ti-header-numbers
			);
		}

		// add ti-profile-image at first element
		if (avatarUrl) {
			let isShowImage = this.data.style[type].show_profile_picture;
			let imageElement = {
				node: 'img',
				attr: {
					src: avatarUrl,
					alt: (sourceType + ' avatar image').trim(),
				}
			};

			if (isShowImage && 'undefined' !== typeof this.data.sprite && (!TrustindexFeed.isAdminUrl() || this.isWordpress)) {
				// calculate y position in the sprite
				let posY = 0;
				let posIndex = 0;
				let heightKey = ('header' === type ? 'header' : 'card') + '_height';
				let imageId = this.data.sprite[heightKey] + '-' + avatarUrl;
				if (this.data.sprite.list.includes(imageId)) {
					for (let i = 0; i < this.data.sprite.list.length; i++) {
						// break if we found the image in the list
						if (this.data.sprite.list[i] === imageId) {
							break;
						}

						// increase position
						let position = parseInt(this.data.sprite.list[i].split('-')[0]);
						posY += isNaN(position) ? this.data.sprite.header_height : position;
						posIndex++;
					}
				}

				let style = 'background-position: 0 '+ (-1 * posY) + 'px';

				if (this.isWordpress && 'header' === type) {
					let height = this.getHeaderProfileImageHeight(this.data.source_types);
					style = 'background-position: 0 '+ (-1 * posIndex * height) + 'px; background-size: '+ height + 'px auto';
				}

				imageElement = {
					node: '.ti-profile-image-sprite',
					attr: {
						'style': 'background: url("'+ this.data.sprite.image_url +'");' + style,
						'data-background-position': posIndex.toString(),
					}
				};
			}

			element.unshift({
				node: '.ti-profile-image',
				attr: { _show: isShowImage },
				content: imageElement
			});
		}

		return element;
	}

	buildDropdownList(dropdownId, profiles) {
		return {
			node: '.ti-btn-dropdown',
			attr: {
				'data-id': dropdownId
			},
			content: {
				node: '.ti-btn-dropdown-inner',
				content: profiles.map((profile) => {
					return {
						node: 'a.ti-btn-dropdown-item',
						attr: {
							'href': profile.url,
							'target': '_blank',
							'data-source': profile.type.split(' ')[0]
						},
						content: [{
								node: '.ti-platform'
							},
							profile.type + ('summary' === profile.url ? '' : ' (' + profile.name + ')' ),
						]
					};
				}),
			}
		};
	}

	initMasonry() {
		const layoutContainer = this.content.querySelector('.ti-widget-layout');
		if ('masonry' === this.data.style.layout.type && layoutContainer) {
			console.log('[Trustindex Feed] init masonry');
			this.masonry = new TrustindexFeedMasonryModule(layoutContainer);
		}
	}

	destoryMasonry() {
		if ('masonry' !== this.data.style.layout.type || !this.masonry) {
			return
		}

		console.log('[Trustindex Feed] destroy masonry');
		this.masonry.destroy();
	}

	slideFeed(slider, isNext, isManual, speed) {
		// move the slider by the direction of isNext

		if (!slider.state) {
			slider.state = 0;
		}

		// stop autoslide on manual nav
		if (isManual && 'undefined' !== typeof slider.intervalPointer) {
			this.stopAutoslide(slider);
		}

		// get related nav arrows
		let navArrow = null;
		slider.parentNode.childNodes.forEach((sibling) => {
			if (sibling.matches('.ti-nav-arrow')) {
				navArrow = sibling;
			}
		});

		// get related pager dots
		let navDots = null;
		if ('widget-card' === slider.dataset.target) {
			// post content slider
			navDots = slider.parentNode.querySelector('.ti-nav-dots');
		} else if ('widget' === slider.dataset.target) {
			// widget slider
			navDots = slider.closest('.ti-widget').querySelector('.ti-widget-footer .ti-nav-dots');
		}

		let slideWidth = slider.clientWidth;
		let actState = slider.state;
		slider.state += (isNext ? -1 : 1);

		slider.lastMovement = [actState, slider.state].map((pos) => (pos * slideWidth) + 'px');

		if (navArrow) {
			// show hidden nav item
			let hiddenNav;
			if (hiddenNav = navArrow.querySelector('[style*="visibility"]')) {
				hiddenNav.style.removeProperty('visibility');
			}

			// check the slider has next or prev sibling
			let isLigthboxHasNext = slider.closest('.ti-lightbox') && 'scroller' !== slider.closest('.ti-lightbox').dataset.lightboxType && this.lightbox.siblings[isNext ? 'next' : 'prev'];
			if (!this.sliderHasNext(slider, isNext) && !isLigthboxHasNext) {
				// hide arrows if is no next slide
				navArrow.querySelector('.ti-arrow-' + (isNext ? 'next' : 'prev')).style.visibility = 'hidden';
			}
		}

		if (navDots) {
			// shift active nav dot by the slide direction
			let activeClass = 'ti-active';
			let activeDot = navDots.querySelector('.' + activeClass);
			let sibling = (isNext ? 'next' : 'previous') + 'Sibling';
			activeDot[sibling].classList.add(activeClass)
			activeDot.classList.remove(activeClass);
		}

		// before sliding, trigger readmore hide actions on current slide
		slider.children[Math.abs(actState)].querySelectorAll('.ti-readmore-collapse').forEach(this.readmoreToggle);

		// load next
		const actSlide = slider.children[Math.abs(slider.state)];

		if (!actSlide) {
			return;
		}

		// load the images on viewport
		this.slide(slider, slider.lastMovement, speed);

		// init video on current slide on lightbox
		if (slider.closest('.ti-lightbox')) {
			this.videoToggle(actSlide.querySelector('video'));
		}

		if ('widget' === slider.dataset.target && this.hasMorePosts()) {
			this.renderSlide();
		}
	}

	slide(slider, movement, speed) {
		// move the slider by the movement positions
		console.log('[Trustindex Feed] slide:', slider.lastMovement.join(' -> '), speed, slider);

		for (const slideItem of slider.children) {
			slideItem.animate({
				left: movement
			}, {
				duration: speed, // duration length
				fill: 'both', // both to not animate back,
				easing: 'ease-in-out'
			});
		}
	}

	sliderHasNext(slider, isNext) {
		// check the slider has more slide on the sliding direction (isNext) and appends it dynamically by the not rendered slides
		if (!slider.state) {
			slider.state = 0;
		}

		let actSlide = slider.children[Math.abs(slider.state)];

		if (
			// no loaded slide on next sibling, but...
			!actSlide && isNext
			// it is widget slider with not loaded posts
			&& 'widget' === slider.dataset.target
			&& this.hasMorePosts()
		) {
			// render next slide
			console.log('[Trustindex Feed] render next slide', slider);

			this.renderSlide();

			if ('true' === this.data.style.layout.infinity_loop) {
				const navDots = this.content.querySelector('.ti-widget-footer .ti-nav-dots');
				if (navDots) {
					let dots = navDots.children.length,
						activeDot = Array.from(navDots.children).indexOf(navDots.querySelector('.ti-active'));
					navDots.replaceWith(TrustindexWidgetBuilder.buildContentHTML(null, [this.buildNavDots(dots+1, activeDot+1)]));
				}
			}

			// init text overflows
			this.initTextOverflow(slider.children[Math.abs(slider.state)]);

			if ('true' === this.data.style.autoplay_widget_card.enabled) {
				let sliders = this.content.querySelectorAll('.ti-widget [data-behaviour="slide"][data-target="widget-card"]');
				if (sliders) {
					sliders.forEach((slider) => {
						if (slider.children.length > 1 && !slider.intervalPointer) {
							this.setAutoslide(slider, this.data.style.autoplay_widget_card.interval);
						}
					});
				}
			}

			return this.hasMorePosts();
		} else {
			// switch to pre-rendered slide...
			let sibling = actSlide[((isNext ? 'next' : 'previous') + 'Sibling')],
				isMediaNext = Boolean(sibling) && null !== sibling.querySelector('img,video,iframe'),
				isInfinityAndHasMore = (isNext && 'widget' === slider.dataset.target && this.hasMorePosts());

			return isMediaNext || isInfinityAndHasMore;
		}

	}

	setAutoslide(slider, timeout) {
		// set up auto slide
		console.log('[Trustindex Feed] autoplay started ... (' + timeout + ' sec)', slider);
		if ('undefined' !== typeof slider.intervalPointer) {
			this.stopAutoslide(slider);
		}
		slider.autoplayTimeout = timeout;
		this.runningIntervals.push(slider.intervalPointer = setInterval(
			() => {
				if ('undefined' === typeof slider.autoplayDirectionIsNext) {
					slider.autoplayDirectionIsNext = true;
				}

				let hasNext = this.sliderHasNext(slider, slider.autoplayDirectionIsNext);
				if (!hasNext) {
					// change direction if has no next/prev slide
					slider.autoplayDirectionIsNext = slider.autoplayDirectionIsNext === hasNext;
				}

				this.slideFeed(slider, slider.autoplayDirectionIsNext, false, 1000);

			},
			slider.autoplayTimeout * 1000
		));
	}

	stopAutoslide(slider) {
		let pointer = null;
		if (slider && 'undefined' !== typeof slider.intervalPointer) {
			pointer = slider.intervalPointer;
		}

		// clear running intervals
		this.runningIntervals.forEach((intervalId, index) => {
			// clear only a specific inverval by pointer or all of them
			if ((pointer && pointer === intervalId) || !pointer) {
				console.log('[Trustindex Feed] autoplay is stopped', intervalId);
				clearInterval(intervalId);
				this.runningIntervals.splice(index, 1);
			}
		});
	}

	renderSlide() {
		TrustindexWidgetBuilder.buildContentHTML(
			this.content.querySelector('.ti-layout-container'),
			[{
				node: 'section.ti-widget-layout',
				content: this.buildCardCollection()
			}]
		);

		this.loadImg(this.content.querySelector('.ti-layout-container'));
		this.initTextOverflow(this.content.querySelector('.ti-layout-container'));
	}
	videoToggle(video) {
		// pause all other feed videos
		document.querySelectorAll('video').forEach((otherVideo) => {
			if (video !== otherVideo) {
				console.log('[Trustindex Feed] pause video', otherVideo);

				otherVideo.pause();
			}
		});

		// play the given video
		if (video) {
			console.log('[Trustindex Feed] play video', video);

			video.setAttribute('loop', ''),
			video.play();
		}
	}

	translate(text, replaceParams = {}) {
		text = 'undefined' !== typeof this.data?.translate?.[text] ? this.data.translate[text] : text;

		for (let key in replaceParams) {
			text = text.replace(new RegExp(key, 'g'), replaceParams[key]);
		}

		return text;
	}

	readmoreToggle(readMore) {
		// hide or show the text of the readMore
		const textContainer = readMore.parentNode.querySelector('.ti-post-text');
		let expanded = 'ti-readmore-collapse',
			isExpanded = readMore.classList.contains(expanded),
			timeout = parseFloat(getComputedStyle(textContainer).transitionDuration) * 800;
		if (isExpanded) {
			readMore.classList.remove(expanded);
			readMore.textContent = readMore.dataset.openText;
			textContainer.style.setProperty('height', textContainer.dataset.initialHeight + 'px', 'important');
			setTimeout(() => {
				textContainer.style.setProperty('-webkit-line-clamp', '');
				textContainer.style.setProperty('height', '');
				this.initMasonry();
			}, timeout);
		} else {
			if (!textContainer.style.height) {
				textContainer.style.setProperty('height', textContainer.dataset.initialHeight + 'px', 'important');
			}
			readMore.classList.add(expanded);
			readMore.textContent = readMore.dataset.collapseText;
			textContainer.style.setProperty('-webkit-line-clamp', 'unset');
			textContainer.style.setProperty('height', textContainer.scrollHeight + 'px', 'important');
			setTimeout(() => this.initMasonry(), timeout);
		}
	}

	loadImg(container, callback) {
		const lastImg = [].slice.call(container.querySelectorAll('img[data-src],img[data-src-small]')).pop();
		let loadHandle = (img, src, callback) => {
			// add loading effect to the image
			img.parentNode.style.backgroundImage = 'url("'+ TrustindexFeed.getCDNUrl() + 'assets/img/loading_dots.gif")';
			img.parentNode.style.backgroundRepeat = 'no-repeat';
			img.parentNode.style.backgroundPosition = '50% 50%';
			img.parentNode.style.backgroundSize = '50px';
			img.style.opacity = 0;
			img.style.visibility = 'hidden';
			img.style.transition = 'opacity .5s';

			// load image
			img.src = src;

			// show image after loaded
			let onload = 'function' === typeof img.onload ? img.onload : () => {};
			img.onload = (event) => {
				img.parentNode.removeAttribute('style');
				img.removeAttribute('style');
				onload(event);

				if ('function' === typeof callback && img === lastImg) {
					callback();
				}
			};

			let originalSrc = img.dataset.srcOriginal;

			// error handling
			img.onerror = (event) => {
				if (!originalSrc || originalSrc === event.target.src) {
					let card = event.target.closest('.ti-widget-card');
					console.error('Failed to load: ', event.target.src, card.dataset.source, card.dataset.postId);

					document.dispatchEvent(new CustomEvent('trustindex-feed-load-failed', {
						detail: {
							'feedInstance': this,
							'type': 'broken-src',
							'message': 'Failed to load image: ' + event.target.src,
							'platform': card.dataset.source,
							'post-id': card.dataset.postId
						},
					}));
				} else {
					loadHandle(event.target, originalSrc, callback);
				}
			};

			// remove previously added attributes
			img.removeAttribute('data-src');
			img.removeAttribute('data-src-original');
			img.removeAttribute('data-src-small');
			img.removeAttribute('data-src-medium');
			img.removeAttribute('data-src-large');
			img.removeAttribute('data-size');
		};

		// calculate image sizes
		let maxImageHeight = 0;
		container.querySelectorAll('img[data-src-small]').forEach((img) => {
			delete img.sizeData;

			// image loaded already
			if (img.getAttribute('src').indexOf('data:image/png') === -1) {
				return false;
			}

			// get image width
			let imageWidth = img.getBoundingClientRect()['width'];

			// slider has no width, but parent element has
			if (imageWidth === 0) {
				imageWidth = img.parentNode.getBoundingClientRect()['width'];
			}

			// container not loaded yet
			if (imageWidth <= 1) {
				return false;
			}

			// get image original sizes
			let tmpSize = img.getAttribute('data-size').split('x');
			let imageOriginalWidth = parseInt(tmpSize[0]);
			let imageOriginalHeight = parseInt(tmpSize[1]);

			let imageOriginalSizes = [
				{
					width: imageOriginalWidth > imageOriginalHeight ? 360 : parseInt(360 * imageOriginalWidth / imageOriginalHeight),
					height: imageOriginalWidth < imageOriginalHeight ? 360 : parseInt(360 * imageOriginalHeight / imageOriginalWidth),
					key: 'small'
				},
				{
					width: imageOriginalWidth > imageOriginalHeight ? 720 : parseInt(720 * imageOriginalWidth / imageOriginalHeight),
					height: imageOriginalWidth < imageOriginalHeight ? 720 : parseInt(720 * imageOriginalHeight / imageOriginalWidth),
					key: 'medium'
				},
				{
					width: imageOriginalWidth,
					height: imageOriginalHeight,
					key: 'large'
				}
			];

			let imageHeight = imageWidth;

			// get parent dimensions if grid item or lightbox item
			if (img.closest('.ti-grid-item') || img.closest('.ti-lightbox')) {
				imageWidth = img.parentNode.getBoundingClientRect()['width'];
				imageHeight = img.parentNode.getBoundingClientRect()['height'];
			}
			// calculate image height based on image ratio
			else {
				if ('landscape' === this.data.style.card.ratio) {
					imageHeight = parseInt(imageWidth * (9/16));
				} else if ('original' === this.data.style.card.ratio) {
					imageHeight = parseInt(imageWidth * (imageOriginalHeight/imageOriginalWidth));
				}
			}

			// calculate image sizes based on device pixel ratio
			let ratio = TrustindexFeed.getDevicePixelRatio();
			imageWidth *= ratio;
			imageHeight *= ratio;

			// get max height
			if (maxImageHeight < imageHeight) {
				maxImageHeight = imageHeight;
			}

			img.sizeData = {
				width: imageWidth,
				height: imageHeight,
				sizes: imageOriginalSizes
			};
		});

		// load image from the calculate size data
		container.querySelectorAll('img[data-src-small]').forEach((img) => {
			if ('undefined' === typeof img.sizeData) {
				return false;
			}

			let width = img.sizeData.width;
			let height = img.sizeData.height;

			if ('original' === this.data.style.card.ratio && 'slider' === this.data.style.layout.type) {
				height = maxImageHeight;
			}

			// calculate height for grid item
			if (img.closest('.ti-grid-item')) {
				let gridColElement = img.closest('.ti-grid-col');
				if (gridColElement) {
					// get item count in the column
					let itemCount = gridColElement.querySelectorAll('.ti-grid-item').length;

					// get gap size between cols setted by CSS
					let gapSize = parseInt(window.getComputedStyle(gridColElement).gap);

					// calculate height
					height = (maxImageHeight - gapSize * (itemCount - 1)) / itemCount;
				}
			}

			// get which image needed
			let src = img.getAttribute('data-src-large');
			for (let i = 0; i < img.sizeData.sizes.length; i++) {
				if (width <= img.sizeData.sizes[i].width && height <= img.sizeData.sizes[i].height) {
					src = img.getAttribute('data-src-' + img.sizeData.sizes[i].key);
					break;
				}
			}

			console.log('[Trustindex Feed] load image: '+ width +'x'+ height, src, img);

			loadHandle(img, TrustindexFeed.getCDNUrl() + src, callback);
		});

		// load one size image
		container.querySelectorAll('img[data-src]').forEach(img => loadHandle(img, img.getAttribute('data-src'), callback));
	}

	initSwipe(container, sliderSelector, callbackX) {
		let touchstartX, touchmoveX, touchstartY, touchmoveY;
		container.addEventListener('touchstart', (event) => {
			// - get the original touch position.
			let slider = event.target.closest(sliderSelector);
			if (!slider) {
				return;
			}
			touchstartX = event.touches[0].pageX;
			touchstartY = event.touches[0].pageY;
			touchmoveX = null;
			touchmoveY = null;
		}, { passive: true });

		container.addEventListener('touchmove', (event) => {
			// - continuously return touch position
			let slider = event.target.closest(sliderSelector);
			if (!slider) {
				return;
			}
			touchmoveX = event.touches[0].pageX;
			touchmoveY = event.touches[0].pageY;
		}, { passive: true });

		container.addEventListener('touchend', (event) => {
			// - swipe occurs
			let slider = event.target.closest(sliderSelector);
			if (!slider) {
				return;
			}
			if (touchstartX && touchmoveX && Math.abs(touchstartX - touchmoveX) > 60 && 'function' === typeof callbackX) {
				callbackX(slider, touchstartX, touchmoveX);
			}
			// - set vars to initial
			touchstartX = null;
			touchmoveX = null;

		}, { passive: true });
	}

	getHeaderProfileImageHeight(type) {
		// clone header profile image
		let element = document.createElement('div');
		element.innerHTML = '<div class="ti-widget" style="display: none" data-wkey="'+ this.data['widget-key'] +'" data-style="'+ this.data.style.type +'" data-widget-type="social"><div class="ti-widget-header" data-header-type="'+ this.data.style.header.type +'" data-source="'+ type +'"><div class="ti-profile"><div class="ti-profile-image"></div></div></div></div>';
		element = element.querySelector('.ti-widget');

		// add to dom (hidden)
		document.body.appendChild(element);

		// get css height
		let height = NaN;
		let profileImage = element.querySelector('.ti-profile-image');
		if (profileImage) {
			height = parseInt(getComputedStyle(profileImage)['height']);
		}

		element.remove();

		return isNaN(height) ? 60 : height;
	}

	click(container, targetSelector, action) {
		let clickEvent = (event) => {
			if (event.target.matches(targetSelector) && !this.isClicked) {
				// temporarily set flag to clicked to prevent multiple fires
				this.isClicked = true;

				action(event);

				setTimeout(() => this.isClicked = false, 100);
			}
		};
		container.addEventListener('click', clickEvent);
		// set up touch event because of IOS clicks may "click through" the button sometimes, so touch will trigger the click event
		container.addEventListener('touchend', (event) => {
			if (event.target.matches(targetSelector)) {
				event.preventDefault();
				event.target.click();
			}
		})
	}

	registerEvents() {
		this.content.addEventListener('click', (event) => {
			// next, prev button
			if (event.target.matches('.ti-nav-arrow > *')) {
				event.preventDefault();

				// find related slider in nav siblings
				const slider = event.target.closest('nav').parentNode.querySelector('[data-behaviour="slide"]');

				if (slider) {
					this.slideFeed(slider, event.target.classList.contains('ti-arrow-next'), true, 500);
				} else {
					console.error('[Trustindex Feed] slider cannot be found that related to: ', event.target.closest('nav'));
				}
			}
			// card click action
			else if (event.target.matches('.ti-widget .ti-card-body *:not(.ti-readmore, .ti-nav-arrow > *, a)')) {
				event.preventDefault();

				if ('lightbox' === this.data.style.card.click_action) {
					// init lightbox
					if (event.target.matches('.ti-lightbox *')) {
						return;
					}

					// open new page in wordpress
					if (this.isWordpress && !TrustindexFeed.isAdminUrl()) {
						return TrustindexFeed.getTargetWindow().open(event.target.closest('.ti-widget-card').dataset.url, '_blank');
					}

					// if the clicked card is a carousel album, open it in its current state
					let slider = event.target.closest('.ti-widget-card').querySelector('[data-behaviour="slide"]'),
						mediaIndex = slider && slider.state ? Math.abs(slider.state) : 0;
					this.lightbox.open(event.target.closest('.ti-widget-card').dataset.postId, mediaIndex);
					// animated open lightbox
					this.lightbox.content.animate({
						opacity: ['0', '1']
					}, 300);
				} else if('redirect' === this.data.style.card.click_action) {
					// redirect to the original post
					TrustindexFeed.getTargetWindow().open(event.target.closest('.ti-widget-card').dataset.url, '_blank');
				}
			}
			// toggle readmore
			else if (event.target.matches('.ti-readmore')) {
				this.readmoreToggle(event.target);
			}
			// toggle element (dropdown)
			else if (event.target.closest('.ti-header[data-header-switch="true"] [data-target="profile-switch"], [data-target="link-tree"]')) {
				let delegateTarget = event.target.closest('[data-target]'),
					triggeredElement = this.content.querySelector('[data-id="' + delegateTarget.dataset.target + '"]');
				if (triggeredElement) {
					if (!triggeredElement.classList.contains('ti-active')) {
						delegateTarget.classList.add('ti-active');
						triggeredElement.classList.add('ti-active');

						// remove active class with every other click (outside or any dropdown item click)
						setTimeout(() => {
							this.content.addEventListener('click', () => {
								delegateTarget.classList.remove("ti-active");
								triggeredElement.classList.remove("ti-active");
							}, {
								once: true
							});
						}, 50);
					}
				}
			}
			// profile switch
			else if (event.target.matches('[data-id="profile-switch"] .ti-btn-dropdown-item')) {
				event.preventDefault();
				let profileItem = event.target,
					profileId = profileItem.getAttribute('href'),
					timeout = 300;

				// hide widget than reset..
				this.content.animate({
					filter: ['blur(0px)', 'blur(5px)']
				}, timeout);
				setTimeout(() => {
					this.destroy();
					this.init(profileId);
				}, timeout);
			}
			// load more
			else if (event.target.matches('.ti-nav-loadmore .ti-btn')) {
				event.preventDefault();

				TrustindexWidgetBuilder.buildContentHTML(
					this.content.querySelector('.ti-widget-layout'),
					this.buildCardCollection({'data-ti-loading':'true', 'style':'opacity: 0;'})
				);

				this.loadImg(this.content.querySelector('.ti-layout-container'));

				// fade in
				this.content.querySelectorAll('[data-ti-loading]').forEach((newItem) => {
					newItem.animate({ 'opacity': [0, 1] }, 300);
					newItem.removeAttribute('style');
					newItem.removeAttribute('data-ti-loading');
					if ('masonry' === this.data.style.layout.type) {
						this.masonry.addItem( newItem );
					}
				});

				setTimeout(() => {
					// init text overflows
					this.initTextOverflow();

					if ('masonry' === this.data.style.layout.type) {
						// update masonry
						this.masonry.refresh();
					}

					// init slider
					if ('true' === this.data.style.autoplay_widget_card.enabled) {
						let sliders = this.content.querySelectorAll('.ti-widget [data-behaviour="slide"][data-target="widget-card"]');
						if (sliders) {
							sliders.forEach((slider) => {
								if (slider.children.length > 1 && !slider.intervalPointer) {
									this.setAutoslide(slider, this.data.style.autoplay_widget_card.interval);
								}
							});
						}
					}
				}, 300);

				// no more posts
				if (!this.hasMorePosts()) {
					event.target.closest('.ti-nav-loadmore').remove();
				}
			}
		}, false);

		// add slider touch events to each slider
		this.initSwipe(this.content, '.ti-layout-container[data-behaviour="slide"]', (slider, startX, moveX) => {
			// stop autoplay
			this.stopAutoslide(slider);

			// - get direction false: left, true: right
			let direction = startX > moveX;

			if (this.sliderHasNext(slider, direction)) {
				this.slideFeed(slider, direction, true, 500);
			}
		});

		// resize
		window.oldWidth = window.innerWidth;
		window.addEventListener('resize', (event) => this.resize());
	}

}