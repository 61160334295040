export default class TrustindexWidgetBuilderModule {
	constructor(isEditable = false) {
		this.isEditable = isEditable;
	}

	createElement({node, attr, onload}) {
		// create a node by the given object
		let tagName = '',
			classList = null,
			nodeDetails = null;

		if (node) {
			nodeDetails = node.split('.');
			tagName = nodeDetails.shift();
			classList = nodeDetails;
		}

		if (!tagName && !tagName.length) {
			tagName = 'div';
		}

		const element = document.createElement(tagName);

		if ('function' === typeof onload) {
			element.onload = onload;
		}

		if (classList) {
			if ('undefined' === typeof attr) {
				attr = {};
			} else if ('undefined' !== typeof attr.class) {
				classList.push(...attr.class.split(' '));
			}
			Object.assign(attr, {
				'class': classList.join(' ')
			});
		}

		if (attr && 'object' === typeof attr) {
			if (attr._show) {
				// hide component
				if ('false' === attr._show) {
					// in edit mode add component with display:none
					if (this.isEditable) {
						attr.style = 'display:none!important;';
					} else {
						// in live mode skip none displayed component
						return null;
					}
				}
				delete attr._show;
			}

			for (const [name, value] of Object.entries(attr)) {
				if (value && value.length) {
					element.setAttribute(name, value);
				}
			}
		}

		return element;
	}

	buildContentHTML(parent, contentList) {
		// append parent by the objects of the contentList array
		for (let i = 0; i < contentList.length; i++) {
			let child = contentList[i];

			if (!child || 0 === Object.keys(child).length) {
				continue;
			}

			let element;

			if ('string' === typeof child ) {
				element = child.trim();
			} else {
				element = this.createElement(child);

				if (!element) {
					continue;
				}

				if (child.content) {
					// child has its own content.. build it with the same mechanism
					if (!Array.isArray(child.content)) {
						// builder needs contents in array

						if ('string' === typeof child.content) {
							child.content = child.content.trim();
						}

						child.content = [child.content];
					}

					element = this.buildContentHTML(element, child.content);
				}
			}

			if (!parent) {
				return element;
			}

			if ('string' === typeof element) {
				// append with string content
				parent.insertAdjacentHTML('beforeend', element);
			} else {
				parent.append(element);
			}

		}

		return parent;
	}
}