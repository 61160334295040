import Masonry from 'masonry-layout';

export default class TrustindexFeedMasonryModule {
	constructor(layoutContainer) {
		this.msnry = new Masonry(layoutContainer, {
			itemSelector: '.ti-layout-item',
			columnWidth: '.ti-grid-sizer',
			percentPosition: true
		});
	}

	refresh() {
		this.msnry.layout();
	}

	addItem(newItem) {
		this.msnry.appended(newItem);
	}

	destroy() {
		this.msnry.destroy();
	}
}